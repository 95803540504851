<script lang="ts">
	import robotArm from "../assets/images/robot-arm.png?webp&h=32&imagetools";
	import gears from "../assets/images/gears.png?webp&h=32&imagetools";
	import reader from "../assets/images/reader.png?webp&h=32&imagetools";
	import automation from "../assets/images/automation.png?webp&h=32&imagetools";
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import DecoratedHeadline from "./DecoratedHeadline.svelte";
	import { nbspify } from "../../../core/utils/nbspify.js";
	import ServiceList from "./ServiceList.svelte";
</script>

<div class="container flex flex-col items-center gap-16">
	<DecoratedHeadline>{nbspify("Naše služby")}</DecoratedHeadline>
	<div class="max-w-[68.75rem] text-center text-[1.375rem] font-bold text-[#C4C4C4] sm:text-lg lg:text-[3rem]">
		Vyrábíme
		<StaticPicture
			image={automation}
			alt="automatizace"
			width={32}
			height={32}
			class="inline-flex align-middle"
			imgClass="w-8 h-8 md:h-auto md:w-auto"
		/>
		<span class="text-gray-400">{nbspify("regály, plastové boxy a kontejnery")}</span> <br />
		{nbspify("s využitím")}
		<StaticPicture
			image={robotArm}
			alt="robotická ruka"
			width={32}
			height={32}
			class="inline-flex align-middle"
			imgClass="w-8 h-8 md:h-auto md:w-auto"
		/>
		<span class="text-gray-400">{nbspify("nejnovějších technologií.")}</span> <br />
		{nbspify("Všechny produkty byly")}
		<StaticPicture
			image={gears}
			alt="ozubená kola"
			width={32}
			height={32}
			class="inline-flex align-middle"
			imgClass="w-8 h-8 md:h-auto md:w-auto"
		/>
		<span class="text-gray-400">
			{nbspify("vyvinuty, testovány")}
			<br />
			{nbspify("a optimalizovány")}
		</span>
		{nbspify("v našem oddělení")}
		<br />
		{nbspify("Výzkumu a vývoje a jsou")}
		<StaticPicture
			image={reader}
			alt="čtečka"
			width={32}
			height={32}
			class="inline-flex align-middle"
			imgClass="w-8 h-8 md:h-auto md:w-auto"
		/>
		<span class="text-gray-400">{nbspify("certifikovány nezávislými institucemi.")}</span>
	</div>

	<div
		id="sluzby"
		class="grid -scroll-mt-36 grid-cols-2 items-start justify-center gap-4 lg:mt-16 lg:grid-cols-4 lg:gap-6"
	>
		<ServiceList />
	</div>
</div>
